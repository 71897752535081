import {
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../shared/constants/ActionTypes';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {API_ENPOINT, ROOT_PATH} from '../../shared/constants/AppConst';
import axios from 'axios';
import {getDataUser} from './Auth0';
import {history} from 'redux/store';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const onJwtUserSignUp = (user) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {
      email: user.email,
      name: user.name,
      password: user.password,
      confirm_password: user.confirmPassword,
    };
    try {
      const res = await jwtAxios.post('register', body);
      // console.log(res)
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({email, password}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, password};
    try {
      // const res = await jwtAxios.post('login', body);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(API_ENPOINT + '/login', body, config);
      // console.log(res)
      localStorage.setItem('token', res.data.data.token);
      dispatch(setJWTToken(res.data.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      console.log(err.response.data.message);
      dispatch(fetchError(err.response.data.message));
    }
  };
};

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const {
        data: {data: user},
      } = await jwtAxios.get('/me');
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: await getDataUser({
          authType: AuthType.JWT_AUTH,
          displayName: user.name,
          email: user.email,
          role: user.roles.map((role) => role.slug),
          token: user._id,
          photoURL: user.avatar,
          id: user?.id,
          person: user?.person,
        }),
      });
    } catch (err) {
      console.log('error!!!!', err.response.error);
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    const res = await jwtAxios.get(API_ENPOINT + '/logout');
    // console.log(res)
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      localStorage.removeItem('token');
      history.push(`${ROOT_PATH}/signin`);
    }, 500);
  };
};
